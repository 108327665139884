<template>
    <div>
        <b-card>
            <h2>My Groups</h2>
            <div class="d-flex">
                <router-link
                    :to="{ name: 'add_group' }"
                >
                    <font-awesome-icon
                        icon="plus-circle"
                        class="mr-1"
                    ></font-awesome-icon>
                    Add
                </router-link>
            </div>
        </b-card>
        <b-card-group class="mt-4" columns>
            <b-card
                v-for="(group, idx) in groups"
                :key="idx"
            >
                <h4>{{ group.group_name }}</h4>
                <div class="mt-2">
                    <b-button
                        variant="link"
                        @click="switchGroup(group)"
                    >
                        <font-awesome-icon icon="exchange-alt" class="mr-2" />
                        Switch
                    </b-button>
                </div>
            </b-card>
        </b-card-group>
    </div>
</template>
<script>
export default {
    data() {
        return {
            groups: [],
            selected: null
        };
    },
    mounted() {
        this.listGroups();
    },
    methods: {
        listGroups() {
            this.$api.get(
                "/api/admin/groups/list"
            ).then(({ data: { records, selected_group_id } }) => {
                this.groups   = records;
                this.selected = selected_group_id;
            });
        },
        switchGroup({ group_id }) {
            this.$api.post(
                "/api/admin/groups/switch",
                { group_id }
            ).then(({ data: { group, semester } }) => {
                this.$store.commit("setGroup",    group);
                this.$store.commit("setSemester", semester);
                console.log(group, semester);
            });
        }
    }
}
</script>